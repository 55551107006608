<template>
    <div class="add-new-cwo">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.cwoCreate')"
            :title="$t('messages.cwoCreate')"
            @submitPressed="submitPressed"
        />
        <cwo-form
            :cwoObject="cwo"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CwoForm from "./components/CwoForm.vue";

export default {
    components: {
        TitleBar,
        CwoForm,
    },
    data() {
        return {
            action: null,
            cwo: {},
        };
    },
    methods: {
        submitPressed() {
            this.action = "add";
        },
    },
};
</script>
